<template>
  <div class="mt-2">
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
          :tbody-tr-class="rowClass"
        >
          <!-- Loader -->
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <!-- Header -->
          <template
            slot="thead-top"
            slot-scope="data"
            v-if="Object.keys(dbRowData).length && items.length"
          >
            <td v-for="(field, i) in data.fields" :key="i" class="totalRow">
              <b
                >{{ i == 0 && field.key === "detail_date" ? "" : "" }}
                {{
                  i !== 0 && field.key === "book_entry.title"
                    ? checkBalanceLabelbyDate
                    : ""
                }}
                {{
                  i !== 0 && field.key === "entry_in"
                    ? `${checkBalancebyDate("in")}`
                    : null
                }}
                {{
                  i !== 0 && field.key === "entry_out"
                    ? `${checkBalancebyDate("out")}`
                    : null
                }}
              </b>
            </td>
          </template>
          <!-- Entrate -->
          <template #cell(entry_in)="{ item }" v-if="items.length">
            {{ toLocaleCurrency(pos_entry(item)) }}
          </template>
          <!-- Uscite -->
          <template #cell(entry_out)="{ item }" v-if="items.length">
            {{ toLocaleCurrency(Math.abs(neg_entry(item))) }}
          </template>
          <!-- Footer -->
          <template
            slot="bottom-row"
            v-if="Object.keys(dbRowData).length && items.length"
          >
            <td colspan="4">
              <div class="row pagination--intable">
                <div class="show-text">
                  <p>Mostra</p>
                </div>
                <div class="group">
                  <b-form-group
                    label-for="per-page-select"
                    label-cols-sm="3"
                    label-cols-md=""
                    label-cols-lg="3"
                    label-size="sm"
                    md="auto"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      style="width: 68px"
                      size="sm"
                      @change="onPageChange(1)"
                      :disabled="!items.length"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="page">
                  <p>di {{ totalItems }} per pagina</p>
                </div>
                <div class="pagination" v-if="totalItems">
                  <b-pagination
                    v-model="currentPage"
                    limit="3"
                    :total-rows="totalItems"
                    :per-page="perPage"
                    size="sm"
                    @change="onPageChange"
                  ></b-pagination>
                </div>
                <div
                  class="input_pagination"
                  v-if="totalItems && !noInputPaginator"
                >
                  <base-input-pagination
                    @pagination="onInputPagination"
                  ></base-input-pagination>
                </div>
              </div>
            </td>
          </template>
          <template slot="custom-foot" slot-scope="data">
            <template v-if="includeBottomRow && items.length">
              <td
                v-for="(field, i) in data.fields"
                :key="i"
                class="totalRow totalRow--upper-pagination"
              >
                <b
                  >{{ i === 0 ? "Totale: " : "" }}
                  {{
                    i !== 0 && field.key === "gross"
                      ? toLocaleCurrency(fieldSum(items, "gross"))
                      : null
                  }}
                  {{
                    i !== 0 && field.key === "agency_gross"
                      ? toLocaleCurrency(
                          fieldSum(items, "agency_prov_take") +
                            fieldSum(items, "agency_prov_purchase")
                        )
                      : null
                  }}
                  {{
                    i !== 0 && field.key === "saler_gross"
                      ? toLocaleCurrency(
                          fieldSum(items, "saler_prov_take") +
                            fieldSum(items, "saler_prov_purchase")
                        )
                      : null
                  }}
                </b>
              </td>
            </template>
            <template v-if="Object.keys(dbRowData).length && items.length">
              <td
                v-for="(field, i) in data.fields"
                :key="i"
                class="totalRow totalRow--upper-pagination"
              >
                <span
                  v-if="i !== 0 && field.key === 'book_entry.title'"
                  class="info"
                  v-html="
                    `Totale Movimenti<br><b>${checkFinalBalanceLabel}</b>`
                  "
                >
                </span>
                <span
                  v-if="i !== 0 && field.key === 'entry_in'"
                  class="info"
                  v-html="
                    `${toLocaleCurrency(
                      dbRowData.entry_in
                    )}<br><b>${checkFinalBalance('in')}</b>`
                  "
                >
                </span>
                <span
                  v-if="i !== 0 && field.key === 'entry_out'"
                  class="info"
                  v-html="
                    `${toLocaleCurrency(
                      Math.abs(dbRowData.entry_out)
                    )}<br><b>${checkFinalBalance('out')}</b>`
                  "
                >
                </span>
              </td>
            </template>
            <template v-if="Object.keys(extraFooter).length && items.length">
              <td v-for="(field, i) in data.fields" :key="i" class="totalRow">
                <span
                  v-if="i !== 0 && field.key === 'additional_description'"
                  class="info"
                  v-html="`Totale Movimenti<br>${checkFinalBalanceLabel}`"
                >
                </span>
                <span
                  v-if="i !== 0 && field.key === 'pos_rebate'"
                  v-html="
                    `${
                      toLocaleCurrency(extraFooter.entry_in) +
                      '<br>' +
                      (extraFooter.entry_in + extraFooter.entry_out > 0
                        ? toLocaleCurrency(
                            extraFooter.entry_in + extraFooter.entry_out
                          )
                        : '')
                    }`
                  "
                >
                </span>
                <span
                  v-if="i !== 0 && field.key === 'neg_rebate'"
                  class="info"
                  v-html="
                  `${
                 toLocaleCurrency(extraFooter.entry_out) +
                        '<br>' +
                        (extraFooter.entry_in + extraFooter.entry_out   &lt; 0
                          ? toLocaleCurrency(
                              extraFooter.entry_in + extraFooter.entry_out
                            )
                          : '')
                  }`
                "
                >
                </span>
              </td>
            </template>
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('infomodal')"
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item.id)"
                class="mt-1 mr-1"
                title="Modifica"
                :disabled="
                  isAbbuono(row.item) || hasAbbuoni() || !isLastRow(row.index)
                "
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('details')"
                size="sm"
                variant="lisaweb"
                @click="row.toggleDetails"
                class="mt-1 mr-1"
                :title="
                  row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'
                "
              >
                <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
                <b-icon v-else icon="chevron-down"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
                :disabled="
                  isRecuperoAcconto(row.item) ||
                  (!isAbbuono(row.item) && hasAbbuoni()) ||
                  (!isLastRow(row.index) &&
                    !hasAbbuoni() &&
                    !isAbbuono(row.item))
                "
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
      <b-row
        v-if="!noPagination && !(Object.keys(dbRowData).length && items.length)"
      >
        <div class="show-text">
          <p>Mostra</p>
        </div>
        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
        <div class="input_pagination" v-if="totalItems && !noInputPaginator">
          <base-input-pagination
            @pagination="onInputPagination"
          ></base-input-pagination>
        </div>
      </b-row>
      <b-modal
        :id="infomodalName"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="xl"
        scrollable
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <button-group-tab> </button-group-tab>

              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import ButtonGroupTab from "@/components/ButtonGroupTab";
import General from "@/components/accounting/book-entries/details/General.vue";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import moment from "moment";
moment.locale("it");

export default {
  name: "EntryDetailsTable",
  extends: template,
  data() {
    return {
      args: null,
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      tabs: [{ name: "General", text: "Generale" }],
      selected: "General",
      modalResourceId: null,
      tabIndex: 0,
    };
  },
  computed: {
    checkStartDate() {
      return this.startDate
        ? !moment(this.startDate, "YYYY-MM-DD").isAfter(
            this.dbRowData?.initial_balance_date,
            "day"
          )
        : true;
    },
    checkBalanceLabelbyDate() {
      return toLocaleDate(this.dbRowData.initial_balance_date) ===
        toLocaleDate(this.startDate)
        ? `Saldo Iniziale al ${toLocaleDate(moment(this.startDate))}`
        : !this.startDate
        ? ""
        : `Saldo al ${toLocaleDate(
            moment(this.startDate).subtract(1, "d").format("YYYY-MM-DD")
          )}`;
    },
    checkFinalBalanceLabel() {
      return this.endDate
        ? `Saldo Finale al ${toLocaleDate(moment(this.endDate))}`
        : "Saldo Finale";
    },
    checkBalancebyDate() {
      return (type) => {
        const final_balance = this.checkStartDate
          ? this.dbRowData.initial_balance
          : this.dbRowData.initial_balance +
            this.currentBalance.entry_in +
            this.currentBalance.entry_out;
        return (type === "in" && final_balance > 0) ||
          (type === "out" && final_balance < 0)
          ? toLocaleCurrency(Math.abs(final_balance))
          : "";
      };
    },
    checkFinalBalance() {
      return (type) => {
        const final_balance =
          this.dbRowData.initial_balance +
          this.dbRowData.entry_in +
          this.dbRowData.entry_out +
          this.currentBalance.entry_in +
          this.currentBalance.entry_out;
        return (type === "in" && final_balance > 0) ||
          (type === "out" && final_balance < 0)
          ? toLocaleCurrency(Math.abs(final_balance))
          : "";
      };
    },
  },
  methods: {
    toLocaleCurrency,
    toLocaleDate,
    pos_entry(item) {
      return item.gross > 0 ? item.gross : 0;
    },
    neg_entry(item) {
      return item.gross < 0 ? item.gross : 0;
    },
    check_sign(amount, op = "gt", val = 0) {
      switch (op) {
        case "gt":
          return amount > val;
        case "lt":
          return amount < val;
        case "gte":
          return amount >= val;
        case "lte":
          return amount <= val;
        case "eq":
          return amount == val;
        default:
          return null;
      }
    },
    getDeferredId(item) {
      return item.treasury.code === "SS" && parseFloat(item.gross) < 0
        ? item.id
        : null;
    },
    isLastRow(index) {
      return index === this.items.length - 1;
    },
    hasAbbuoni() {
      let allIds = [];
      let items = this.$parent.$parent.details || [];
      for (const i of items) {
        let id = this.getDeferredId(i);
        if (id) allIds.push(id);
      }
      for (const id of allIds) {
        const found = items.find((e) => e.id === id);
        if (found && found.entry_details) {
          const found2 = found.entry_details.find(
            (e) => e.treasury.code === "AB"
          );
          if (found2) {
            return true;
          }
        }
      }
      return false;
    },
    isAbbuono(item) {
      return item?.treasury?.code === "AB";
    },
    isRecuperoAcconto(item) {
      // #1008
      return item?.treasury?.code === "UC";
    },
    rowClass(item) {
      if (item && this.isDeferred) {
        if (this.deferredStatus === "Chiuso") {
          return "deferred-status-2";
        } else {
          if (!item?.reduced) {
            return "deferred-status-1";
          } else {
            return "deferred-status-0";
          }
        }
      } else {
        return "deferred-status-2";
      }
    },
  },
  beforeMount() {
    this.args = this.$parent.$parent.args;
  },
  components: {
    BaseIcon,
    General,
    ButtonGroupTab,
  },
  props: {
    isDeferred: {
      type: Boolean,
      default() {
        return false;
      },
    },
    deferredStatus: {
      type: String,
      default() {
        return null;
      },
    },
    currentBalance: {
      type: Object,
      default() {
        return {};
      },
    },
    startDate: {
      type: String,
      default() {
        return null;
      },
    },
    endDate: {
      type: String,
      default() {
        return null;
      },
    },
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
    extraFooter: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>

/** inserisce un gradiente orizzontale nella prima cella della table */
/* ROSSO */
:deep(.deferred-status-0 td:nth-child(2)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(228, 33, 33, 0.3) 100%
  );
}
/* VERDE */
:deep(.deferred-status-1 td:nth-child(2)) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(19, 172, 19, 0.4) 100%
  );
}
/* NEUTRO */
:deep(.deferred-status-2 td:nth-child(2)) {
  background: rgb(255, 255, 255);
}

:deep(.b-table-bottom-row) {
  background-color: #e1ddda;
}
.totalRow {
  text-align: right;
}
.info {
  text-align: right;
}
.b-table-bottom-row {
  td {
    background-color: #fafafa;
    padding-block: 8px;
  }
  .row {
    margin: 0;
    justify-content: center;
    align-items: center;
    * {
      margin: 0;
    }
    .group {
      width: auto;
    }
    .page {
      + .pagination {
        padding-left: 10px;
        margin-left: 0;
        border-left: 1px solid #ccc;
        border-radius: 0;
      }
    }
    .pagination {
      width: auto;
    }
  }
}
tfoot,
.totalRow--upper-pagination {
  background-color: #e1ddda;
}
.table thead th,
thead,
th {
  background: 0 !important;
}
</style>

<template>
  <div class="mt-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-datepicker
                name="book_date"
                label="Data Registrazione Dal"
                v-model="filter.byCalendar.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="book_date"
                label="Data Registrazione al"
                v-model="filter.byCalendar.to"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="cod_prod"
                label="Produttore"
                :options="salesmen"
                v-model="filter.byBroker.id"
                :multiple="true"
                :closeOnSelect="false"
                :taggable="salesmen_taggable"
                :defaultHtmlMultipleSelect="true"
                @changeTaggable="changeTaggable"
              />
            </div>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>

    <!-- REVIEW story-36-a: enable this when BE can handle sort requests!!! -->
    <!-- sortField="book_date" -->

    <export-options
      :exportUrl="exportUrl"
      :repository="repository"
      :resource="resource"
      :tableRef="$refs[tableRef]"
      :filter="filter"
      :options="[
        {
          code: 'LISTABB',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
    ></export-options>
    <book-entries
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterOn="{
        bySector: { display: 'ABB' },
        byRelations: [
          'byBroker',
          'byDocument',
          'byTask',
          'byRegistry',
          'byInsurer',
          'byInsuranceAncillary',
          'byVariousAccounting',
          'byBookTag',
        ],
      }"
      :filterName="filterName"
      :ref="tableRef"
      :hasChecksButtonGroup="false"
      noActions
      :extraFooter="rebate_entry"
      sortField="book_date"
    ></book-entries>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import BookEntries from "@/components/tables/BookEntries";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { mapActions } from "vuex";
import { toLocaleDate } from "@/utils/dates";
import { toInfoData } from "@/utils/transforms";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { mapGetters } from "vuex";
import BaseSelect from "@/components/form/BaseSelect";
import ExportOptions from "@/components/ExportOptions";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      exportUrl: null,
      filter: this.initFilter(),
      filterName: "filterBookEntryRebate",
      resource: "book_entries",
      repository: "book_entry",
      tableRef: "BookEntryRebateTableRef",
      rebate_entry: {},
      salesmen: [],
      salesmen_taggable: true,
      cooperators: [],
      risk_branches: [],
      companies: [],
      products: [],
      risks: [],
      book_tags_opt: [],
      notes: [],
      documents: [],
      registry_data: null,
      insurance_policy_data: null,
      form: {
        inpt_label_policy: "",
        inpt_label_registry: "",
        quick_value: "",
      },
      fields: [
        {
          key: "book_date",
          label: this.getDictionary("book_date", "book_entry"),
          formatter: (value) => toLocaleDate(value),
        },
        {
          key: "title",
          label: this.getDictionary("title", "book_entry"),
        },
        {
          key: "broker",
          label: this.getDictionary("broker", "book_entry"),
        },
        {
          key: "pos_rebate",
          label: this.getDictionary("pos_rebate", "book_entry"),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "neg_rebate",
          label: this.getDictionary("neg_rebate", "book_entry"),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
      ],
    };
  },
  components: {
    BaseDatepicker,
    BookEntries,
    BaseSelect,
    ExportOptions,
  },
  methods: {
    toInfoData,
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    initFilter() {
      let init = {
        byCalendar: {
          from: null,
          to: null,
        },
        byBroker: {
          id: null,
        },
      };
      return init;
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.fetchSummary();
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
    },
    fetchSummary() {
      const Repo = RepositoryFactory.get("sector");
      let sectors = this.getSectors();
      const rebate_id = sectors.find((sector) => sector.code === "AB").value;
      const broker = this.filter.byBroker.id
        ? `&byBroker[id]=${this.filter.byBroker.id}`
        : ``;
      Repo.summary(
        `byAttribute[id]=${rebate_id}${broker}&byCalendar=${this.filter.byCalendar.from},${this.filter.byCalendar.to}`
      )
        .then((response) => {
          this.rebate_entry = {
            entry_in: response.data.data[0].entry_in,
            entry_out: response.data.data[0].entry_out,
          };
          this.$refs[this.tableRef].fetch();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("quicksearch", {
      saveQuickSearchByName: "saveByName",
      removeQuickSearchByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    ...mapGetters("auth", {
      // allTreasuries: "allTreasuries",
      getSectors: "sectors",
      getSalesmen: "salesmen",
    }),
    ...mapGetters("filters", { loadFilterByName: "loadByName" }),
  },
  mounted() {
    if (this.loadFilterByName()(this.filterName)) {
      this.fetchSummary();
    }
    this.salesmen = this.getSalesmen();
  },
};
</script>

<template>
  <div class="mt-1">
    <b-form @submit.prevent="onSearchCustom(filterName)">
      <b-card class="filter">
        <b-row>
          <div class="col-md-3">
            <base-datepicker
              name="registrazione_da"
              label="Data registrazione da"
              v-model="filter.byCalendar.from"
              :min="minDate"
              @select="onDetailDateSelect"
            />
          </div>
          <div class="col-md-3">
            <base-datepicker
              name="registrazione_a"
              label="Data registrazione a"
              v-model="filter.byCalendar.to"
            />
          </div>
          <div class="form-group col-md-4 align-self-end">
            <b-button type="submit" variant="lisaweb" size="sm">Cerca</b-button>
            <b-button
              class="btn-reset"
              type="button"
              variant="lisaweb"
              size="sm"
              @click="onClearFilter(filterName)"
              >Reset</b-button
            >
          </div>
        </b-row>
      </b-card>
    </b-form>
    <export-options
      :exportUrl="exportUrl"
      :repository="repository"
      :resource="resource"
      :tableRef="$refs[tableRef]"
      :filter="filter"
      :options="[
        {
          code: 'ESTCONCO',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
    ></export-options>
    <entry-details
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      :filterOn="{
        byRelations: ['byBookEntry'],
      }"
      noActions
      noInnerWidth
      noInputPaginator
      :dbRowData="sectorData"
      :currentBalance="currentBalance"
      :startDate="searchedStartDate"
      :endDate="searchedEndDate"
      sortField="detail_date"
    >
    </entry-details>
  </div>
</template>

<script>
// import ButtonGroupTable from "@/components/ButtonGroupTable";
import ExportOptions from "@/components/ExportOptions";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import { mapActions } from "vuex";
import EntryDetails from "@/components/tables/EntryDetails";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
// import ExportsMixin from "@/mixins/ExportsMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [CustomFiltersMixin],
  name: "BookEntry",
  data() {
    return {
      exportUrl: null,
      filter: this.initFilter(),
      filterName: "filterBookEntriesSummaryDetail",
      repository: "entry_detail",
      resource: "entry_details",
      tableRef: "bookEntriesSummaryTableRef",
      sectorData: {},
      startDate: null,
      endDate: null,
      searchedStartDate: null,
      searchedEndDate: null,
      currentBalance: {},
      fields: [
        {
          key: "detail_date",
          label: this.getDictionary("detail_date", "entry_detail"),
          formatter: (value) => toLocaleDate(value),
          sortKey: "detail_date",
        },
        {
          key: "book_entry.title",
          label: this.getDictionary("title", "book_entry"),
        },
        {
          key: "entry_in",
          label: this.getDictionary("entry_out_company", "book_entry"),
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
        {
          key: "entry_out",
          label: this.getDictionary("entry_in_company", "book_entry"),
          formatter: (value) => toLocaleCurrency(value),
          thClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          tdClass: "text-right",
        },
      ],
    };
  },
  components: {
    BaseDatepicker,
    EntryDetails,
    // ButtonGroupTable,
    ExportOptions,
  },
  methods: {
    toLocaleCurrency,
    toLocaleDate,
    initFilter() {
      let init = {
        bySector: { id: this.resourceId },
        byCurrency: {
          not: "!0.0",
        },
        byCalendar: {
          from: null,
          to: null,
        },
      };
      return init;
    },
    onSearchCustom(name) {
      let criteria = this.filter;
      // Se il valore del filtro 'data registrazione da' non è inizializzato, prendo il valore 'initial_balance_date' della chiamata summary dei sector
      if (!this.filter.byCalendar.from) {
        this.filter.byCalendar.from = this.dbRowData.initial_balance_date;
      }
      this.saveFilterByName({ name, criteria });
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef]
        .fetch()
        .then(() => {
          this.$refs[this.tableRef].isLoading = true;
          this.searchedStartDate = this.filter.byCalendar.from;
          this.searchedEndDate = this.filter.byCalendar.to
            ? this.filter.byCalendar.to
            : "";
          const Repo = RepositoryFactory.get("sector");
          const queryString = `byAttribute[is_agency]=N&byAttribute[is_system]=N&byAttribute[id]=${this.resourceId}&byCalendar=${this.filter.byCalendar.from},${this.filter.byCalendar.to}`;
          Repo.summary(queryString)
            .then((response) => {
              this.sectorData = response.data.data[0];
              // this.$refs[this.tableRef].isLoading = false;
              // Previous Balance
              this.previous_balance_date = moment(this.filter.byCalendar.from)
                .subtract(1, "d")
                .format("YYYY-MM-DD");
              // Current Balance
              const queryString = `byAttribute[is_agency]=N&byAttribute[is_system]=N&byAttribute[id]=${this.resourceId}&byCalendar=1900-01-01,${this.previous_balance_date}`;
              Repo.summary(queryString)
                .then((response) => {
                  this.currentBalance = response.data.data[0];
                  this.$refs[this.tableRef].isLoading = false;
                })
                .catch((error) => {
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({
                    preset: "error",
                    text: `${errMsg}`,
                  });
                  this.$refs[this.tableRef].isLoading = false;
                });
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
              this.$refs[this.tableRef].isLoading = false;
            });
          // se non rimuove il filtro, la prossima fetch applica i filtri precedentemente salvati!!!
          this.removeFilterByName(name);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        });
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      // this.removeSortByName(name);
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    onDetailDateSelect(date) {
      if (moment(date, "DD/MM/YYYY").isBefore(this.minDate)) {
        let errMsg = `La data non può essere uguale o precedente a ${toLocaleDate(
          this.minDate
        )}`;
        this.$showSnackbar({
          preset: "info",
          text: `${errMsg}`,
        });
        this.filter.byCalendar.from = null;
      }
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
  },
  props: {
    resourceId: Number,
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    minDate() {
      return moment(this.sectorData?.initial_balance_date).format("YYYY-MM-DD");
    },
  },
  beforeMount() {
    this.removeFilterByName(this.filterName);
  },
  mounted() {
    this.filter.byCalendar.from = this.dbRowData.initial_balance_date;
    this.sectorData = this.dbRowData;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-1"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSearchCustom(_vm.filterName)}}},[_c('b-card',{staticClass:"filter"},[_c('b-row',[_c('div',{staticClass:"col-md-3"},[_c('base-datepicker',{attrs:{"name":"registrazione_da","label":"Data registrazione da","min":_vm.minDate},on:{"select":_vm.onDetailDateSelect},model:{value:(_vm.filter.byCalendar.from),callback:function ($$v) {_vm.$set(_vm.filter.byCalendar, "from", $$v)},expression:"filter.byCalendar.from"}})],1),_vm._v(" "),_c('div',{staticClass:"col-md-3"},[_c('base-datepicker',{attrs:{"name":"registrazione_a","label":"Data registrazione a"},model:{value:(_vm.filter.byCalendar.to),callback:function ($$v) {_vm.$set(_vm.filter.byCalendar, "to", $$v)},expression:"filter.byCalendar.to"}})],1),_vm._v(" "),_c('div',{staticClass:"form-group col-md-4 align-self-end"},[_c('b-button',{attrs:{"type":"submit","variant":"lisaweb","size":"sm"}},[_vm._v("Cerca")]),_vm._v(" "),_c('b-button',{staticClass:"btn-reset",attrs:{"type":"button","variant":"lisaweb","size":"sm"},on:{"click":function($event){return _vm.onClearFilter(_vm.filterName)}}},[_vm._v("Reset")])],1)])],1)],1),_vm._v(" "),_c('export-options',{attrs:{"exportUrl":_vm.exportUrl,"repository":_vm.repository,"resource":_vm.resource,"tableRef":_vm.$refs[_vm.tableRef],"filter":_vm.filter,"options":[
      {
        code: 'ESTCONCO',
        label: null,
        formats: ['csv', 'pdf'],
      },
    ]}}),_vm._v(" "),_c('entry-details',{ref:_vm.tableRef,attrs:{"fields":_vm.fields,"repository":_vm.repository,"resource":_vm.resource,"filterName":_vm.filterName,"filterOn":{
      byRelations: ['byBookEntry'],
    },"noActions":"","noInnerWidth":"","noInputPaginator":"","dbRowData":_vm.sectorData,"currentBalance":_vm.currentBalance,"startDate":_vm.searchedStartDate,"endDate":_vm.searchedEndDate,"sortField":"detail_date"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }